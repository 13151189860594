import React from "react"
import { Row, Col } from "reactstrap"
import Layout from "components/Layout/Layout"
import MaxContainer from "components/shared/MaxContainer"
import ImagesWithText from "components/shared/ImagesWithText"
import SEO from "utils/Seo"

import image1 from "images/home/2.jpg"
import image2 from "images/home/4.jpg"


const ContactPage = () => (
    <Layout>
        <SEO title="Contact | Decking Companies | Landscape Gardener Near Me" description="If you have a question for us, or simply want to chat through your options, please don’t hesitate to get in touch! We are here to help & the team here is knowledgeable and passionate about what we do." />
        <MaxContainer>
            <ImagesWithText
                image1={{ src: image1, alt: "landscape gardening" }}
                image2={{ src: image2, alt: "landscape gardening" }}
            >
                <h1>Contact Plantlife Landscapes, Cornwall</h1>
                <Row>
                    <Col xs={3} lg={1}>
                        <p><strong>Address:</strong></p>
                    </Col>
                    <Col xs={9} lg={11}>
                        <p>
                            Vailima, <br />
                            127 Launceston Road, <br />
                            Kelly Bray. <br />
                            PL17 8DT.
                        </p>
                    </Col>
                </Row>
                {/* <Row>
                    <Col xs={3} lg={1}>
                        <p><strong>Tel:</strong></p>
                    </Col>
                    <Col xs={9} lg={11}>
                        <p><a href="tel:+01579384031">01579 384031</a></p>
                    </Col>
                </Row> */}
                <Row>
                    <Col xs={3} lg={1}>
                        <p><strong>Mobile:</strong></p>
                    </Col>
                    <Col xs={9} lg={11}>
                        <p><a href="tel:+447958696191">07958 696 191</a></p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={3} lg={1}>
                        <p><strong>Email:</strong></p>
                    </Col>
                    <Col xs={9} lg={11}>
                        <p><a href="mailto:info@plantlifelandscapes.co.uk">info@plantlifelandscapes.co.uk</a></p>
                    </Col>
                </Row>
                <Row>
                    <Col className="pt-4">
                        <iframe title="Plantlife Landscapes map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2535.655385117129!2d-4.281485784265368!3d50.540580979487956!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xbe318c3b9de2294e!2sPlantlife+Landscapes!5e0!3m2!1sen!2suk!4v1562414602841!5m2!1sen!2suk" width="100%" height="300" frameBorder="0" style={{border: 0}} allowFullScreen></iframe>
                    </Col>
                </Row>
            </ImagesWithText>
        </MaxContainer>
    </Layout>
)

export default ContactPage
